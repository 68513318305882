import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<div class="container" fxLayout="column" fxLayoutAlign="center center">
    <h1>Jungle Plane</h1>
    <picture>
      <source
        srcset="assets/images/emblem-white.png"
        media="(prefers-color-scheme: dark)">
      <img src="assets/images/emblem-black.png">
    </picture>
  </div>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Jungle Plane';
}
